.site-section__heading {
	margin-bottom: 1rem;
	&--has-background {
		width:100%;
		margin-bottom: 3rem;
		@include media-breakpoint-up(xl) {
			margin-right: 98px;
		}
		&.heading-style-1 {
			h2 {
				color: $white;
				&::after {
					background-color: $white;
				}
			}
		}
		&.heading-style-2 {
			h2 {
				color: $black;
				&::after {
					background-color: $purple;
				}
			}
		}
		h2 {
			padding-left: 25px;
			padding-top: 100px;
			line-height: 1.41;
			@include media-breakpoint-up(md) {
				padding-left: 13%;
				padding-bottom: 40px;
			}
			&::after {
				content: '';
				display: block;
				width:70px;
				height:8px;
				position: relative;
				top: 40px;
			}
		}
	}
	&--alternate {
		margin-bottom:35px;
		h2 {
			position: relative;
			padding-bottom: 43px;
			padding-top: 90px;
			font-size: 3.25rem;
			line-height: 1.22;
			color: $purple;
			
			&::after {
				content: '';
				display: block;
				width:70px;
				height:8px;
				position: relative;
				top: 40px;
				background-color: $purple;
			}
		}
	}
}
