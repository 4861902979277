/* typography / _fonts */


// EXAMPLE FONT EMBEDDING VIA IMPORT:
// =======================================
// @import 'https://fonts.googleapis.com/css?family=Roboto+Slab:400,700';



// EXAMPLE FONT EMBEDDING IN THEME:
// =======================================

@font-face {
    font-family: 'libre_baskervillebold';
    src: url('../fonts/librebaskerville-bold-webfont.eot');
    src: url('../fonts/librebaskerville-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/librebaskerville-bold-webfont.woff2') format('woff2'),
         url('../fonts/librebaskerville-bold-webfont.woff') format('woff'),
         url('../fonts/librebaskerville-bold-webfont.ttf') format('truetype'),
         url('../fonts/librebaskerville-bold-webfont.svg#libre_baskervillebold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'libre_baskervilleitalic';
    src: url('../fonts/librebaskerville-italic-webfont.eot');
    src: url('../fonts/librebaskerville-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/librebaskerville-italic-webfont.woff2') format('woff2'),
         url('../fonts/librebaskerville-italic-webfont.woff') format('woff'),
         url('../fonts/librebaskerville-italic-webfont.ttf') format('truetype'),
         url('../fonts/librebaskerville-italic-webfont.svg#libre_baskervilleitalic') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'libre_baskervilleregular';
    src: url('../fonts/librebaskerville-regular-webfont.eot');
    src: url('../fonts/librebaskerville-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/librebaskerville-regular-webfont.woff2') format('woff2'),
         url('../fonts/librebaskerville-regular-webfont.woff') format('woff'),
         url('../fonts/librebaskerville-regular-webfont.ttf') format('truetype'),
         url('../fonts/librebaskerville-regular-webfont.svg#libre_baskervilleregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'din-bold';
    src: url('../fonts/mittelschrift-bold-webfont.eot');
    src: url('../fonts/mittelschrift-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/mittelschrift-bold-webfont.woff2') format('woff2'),
         url('../fonts/mittelschrift-bold-webfont.woff') format('woff'),
         url('../fonts/mittelschrift-bold-webfont.ttf') format('truetype'),
         url('../fonts/mittelschrift-bold-webfont.svg#alte_din_1451_mittelschriftRg') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'din-regular';
    src: url('../fonts/mittelschrift-regular-webfont.eot');
    src: url('../fonts/mittelschrift-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/mittelschrift-regular-webfont.woff2') format('woff2'),
         url('../fonts/mittelschrift-regular-webfont.woff') format('woff'),
         url('../fonts/mittelschrift-regular-webfont.ttf') format('truetype'),
         url('../fonts/mittelschrift-regular-webfont.svg#alte_din_1451_mittelschriftRg') format('svg');
    font-weight: normal;
    font-style: normal;

}

// BASE FONTS USED FOR EXTENDS
// ---------------------------------------------

%embedded-serif--regular {
	@include base-font--regular;
}

%base-font--semibold {
	@include base-font--semibold;
}

%base-font--bold {
	@include base-font--bold;
}

// EMBEDDED FONTS USED FOR EXTENDS
// ---------------------------------------------


%embedded-sans--regular {
  @include embedded-sans--regular;
}

%embedded-sans--bold {
  @include embedded-sans--bold;
}

%embedded-serif--regular {
  @include embedded-serif--regular;
}

%embedded-serif--bold {
  @include embedded-serif--bold;
}
