.site-footer {
	.footer-info {
		padding-right: 13%;
		font-size: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: center;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
			justify-content: space-between;
		}

		@extend %embedded-serif--regular;
		p {
			margin-bottom: 7px;
			@extend %embedded-serif--bold;
		}
		.footer-logo {
			background: url(../images/logo.png);
			background-size: 100%;
			background-repeat: no-repeat;
			width: 142px;
			height: 78px;
			display: block;
		}
		div {
			margin-bottom: 10px;
		}
	}
}
