// ---------------------------------------------
// IMPORT MATERIAL COLORS
// ---------------------------------------------
@import "material-colors";


$main-red:                        #C63D0F;
$darker-red:                      darken($main-red,7%);
$dark-grey:                       #495555;
$light-grey:                      #CCCCCC;
$almost-white:                    #F4F5F5;
$white:                           #fff;
$purple:                          #7e0f87;
$darker-purple:                   darken($purple,7%);

$primary-color:								   $purple;
$accent-color:         						$darker-purple;
$primary-text-color:   						$dark-grey;
$secondary-text-color: 						$black;
$divider-color:        						$grey-200;

//-----------------------
// redefine bootstrap color variables (if applicable)
//-----------------------
$btn-primary-color: 						$white;
$btn-primary-bg: 							$purple;
$btn-primary-border: 						$purple;

// $btn-secondary-color: 						$white;
// $btn-secondary-bg: 							lighten($btn-primary-bg,10%);
// $btn-secondary-border: 						lighten($btn-primary-border,10%);

//-----------------------
//define new bootstrap color variables
//-----------------------
//default button (bootstrap secondary)
$btn-default-color:							$grey-800;
$btn-default-bg:							$white;
$btn-default-border:						$grey-200;

//default alert
$alert-default-bg:							$grey-100;
$alert-default-border:						$grey-200;
$alert-default-text: 						$primary-text-color;

//default card:
$card-default-bg: 							$grey-100;
$card-default-border: 						$card-default-bg;

//-----------------------
// theme colors
//-----------------------

//typography
$link-color:								$purple;
$link-color--hover:							$darker-purple;

//header
$site-body-color:							$white;

//header
$site-header-color:							$white;
$site-header-logo-bg:						$primary-color;

//template
$site-template-color:						$white;

//footer
$site-footer-color:							$grey-100;

//primary nav
$primary-nav-link:							$black;
$primary-nav-dropdown:						$black;

//panel
$site-panel-color:							$primary-color;

//sidebar-nav
$sidebar-nav__link:							$primary-text-color;
$sidebar-nav__link-bg--hover:				$grey-200;
$sidebar-nav__link-bg--current:				$primary-color;
$sidebar-nav__link__nested-border:			$grey-600;
$sidebar-nav__link__nested-link:			$primary-color;
$sidebar-nav__section-title:				$black;
$sidebar-nav__section-title--hover:			$grey-200;
$sidebar-nav__section-title--current:		$primary-color;

//-----------------------
// eio colors
//-----------------------

$section-alternate-color: $almost-white;
