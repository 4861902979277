/* typography / _typography */

// ==================================================
// Body
// ==================================================
body {
	background-color: $site-body-color;
	color: $dark-grey;
}

// ==================================================
// General
// ==================================================

a {
	color: $link-color;
	text-decoration: none;

	&:focus,
	&:hover {
		color: $link-color--hover;
		text-decoration: none;
	}
}

// ==================================================
// images
// ==================================================

img {
	max-width: 100%;
	height: auto;
}

// ==================================================
// NAV
// ==================================================

nav {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

// ==================================================
// Paragraphs
// ==================================================

p {
	@extend %embedded-serif--regular;
	line-height: 1.9;

	&.legal {
		font-size: .75em;
	}

	&.intro {
		@extend %embedded-serif--bold;
		font-size: 1.375rem;
		color: $secondary-text-color;
		line-height: 1.82;
		margin:50px 13%;
		&--sm {
			font-size: 1.25rem;
		}
	}
	&.mission {
		&--text {
			@extend %embedded-serif--regular;
			color: $purple;
			font-style: normal;
			text-align: center;
			padding: 45px 80px;
			margin: 0 13%;
			background-color: $almost-white
		}
	}
	&.success {
		@extend %embedded-sans--regular;
		text-transform: uppercase;
		color: $purple;
		font-size: 1.25rem;
	}
	strong {
		@extend %embedded-serif--bold;
	}
}

.bg-style-2 {
	p {
		.intro {
			font-size: 1.25rem;
			line-height: 2;
		}
	}
}

@mixin text-style-1 {
	font-size: 1.25rem;
	@extend %embedded-sans--bold;
	font-weight:800;
	margin-bottom: .25rem;
	color: $dark-grey;
	line-height: 1.35;
}

%text-style-1 {
	@include text-style-1;
	&:hover {
		text-decoration: none;
	}
}

// ==================================================
// Definition Lists
// ==================================================

dl,dt,dd {
	@extend %embedded-serif--regular;
}

// ==================================================
// HR
// ==================================================

hr {
	border: 0;
	height: 0;
	border-top: 1px solid $divider-color;
	margin: 2em 0;
	clear: both;
}

// ==================================================
// Lists
// ==================================================

ol,
ul {
	margin: 2em 0;

	li {
		@extend %embedded-serif--regular;
		color: $primary-text-color;
		margin: 1em 0;

		strong {
			@extend %base-font--bold;
		}
	}
}

.stylized-list {
	display:flex;
	flex-direction: column;
	background-color: $almost-white;
	list-style-type: none;
	counter-reset: numbered;
	padding: 2% 7%;
	margin:0 13%;
	&.columnized--3up {
		li {
			@include media-breakpoint-up(lg) {
				padding-right: 50px;
			}
			@include media-breakpoint-up(md) {
				padding-right: 30px;
			}
		}
	}
	li {
		@extend %embedded-sans--bold;
		position: relative;
		padding-left: 55px;
		line-height: 1.7rem;
		width:100%;
		&::before {
			counter-increment: numbered;
			content: counter(numbered);
			height: 38px;
			width: 22px;
			position: absolute;
			@extend %embedded-sans--bold;
			color: $purple;
			left: 0;
			text-align: center;
			line-height: 28px;
			border-bottom: 1px solid $purple;
		}
	}
}

.inline-list {
	list-style: none;
	margin: 1em 0;
	padding: 0;

	li {
		margin: 0;
		display: inline-block;
	}
}


// ==================================================
// Headers
// ==================================================

h1 {
	@extend %base-font--semibold;
}

h2 {
	@extend %embedded-sans--bold;
	font-size: 2.25rem;
	text-transform: capitalize;
	@include media-breakpoint-up(md) {
		font-size: 3rem;
		line-height: 1.2;
	}

}

h3 {
	@extend %embedded-sans--bold;
	font-size: 2rem;
	margin-bottom: 1em;

	&.mission {
		&--title {
			font-size: 1rem;
			background: $purple;
			color: $white;
			margin-bottom: 0;
			margin: 0 13%;
			padding:20px;
			text-align:center;
			text-transform: uppercase;
		}
	}
}

h4 {
	@extend %base-font--semibold;
	font-size: 1rem;
	margin-bottom: 1em;
}


// ==================================================
// blockquote
// ==================================================

blockquote {
	// even out the spacing on blockquote
	p:last-child {
		margin-bottom: 0;
	}
}

//blockquote
blockquote:not([class]) {
	@extend .blockquote;
}

// ==================================================
// table
// ==================================================

// copy of bootstrap table styles because nested .table .table was an issue when table extended .table
%table {
	width: 100%;
	max-width: 100%;
	margin-bottom: $spacer;

	th,
	td {
		padding: $table-cell-padding;
		vertical-align: top;
		border-top: $table-border-width solid $table-border-color;
	}

	thead th {
		vertical-align: bottom;
		border-bottom: (2 * $table-border-width) solid $table-border-color;
	}

	tbody + tbody {
		border-top: (2 * $table-border-width) solid $table-border-color;
	}
}

table {
	@extend %table;
	thead {
		tr {
			th {
			}
		}
	}

	tbody {
		tr {
			td {
				strong {
				}
			}

			th {
			}
		}
	}

	tfoot {
		tr {
			td {
			}
		}
	}
}
