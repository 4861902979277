.columnized {

	&--3up {
		@include media-breakpoint-up(md) {
			@include clearfix;
		}

		> * {
			@include media-breakpoint-up(md) {
				float: left;
				width: 33%;
			}

			&:nth-child(3n+4) {
				@include media-breakpoint-up(md) {
					clear: both;
				}
			}
		}
	}

	&--2up {
		@include media-breakpoint-up(md) {
			@include clearfix;
		}
		
		> * {
			@include media-breakpoint-up(md) {
				float: left;
				width: 50%;
				padding-right: 25px;
			}

			&:nth-child(2n+3) {
				@include media-breakpoint-up(md) {
					clear: both;
				}
			}
		}
		> dl {
			@include media-breakpoint-up(md) {
				padding-right: 80px;
			}
		}

	}
}
