/* modules / _responsive-table */

// devnote:
// this version of responsive-table heavily leverages overrides on sm breakpoint
.responsive-table {
	@extend %table;
	width: 100%;

	@include media-breakpoint-down(sm) {
		display: block;
	}

	thead {
		tr {
			th {

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
		}
	}

	tfoot {
		@include media-breakpoint-down(sm) {
			display: block;
		}

		tr {
			@include media-breakpoint-down(sm) {
				display: block;
			}

			td {
				@include media-breakpoint-down(sm) {
					display: none;
					border: none;
				}

				p {}
			}
		}
	}

	tbody {
		@include media-breakpoint-down(sm) {
			display: block;
		}

		tr {
			@include media-breakpoint-down(sm) {
				display: block;
				padding: 5px 10px;
				border: 1px solid $divider-color;
				margin-top: 10px;
			}

			td {
				@include media-breakpoint-down(sm) {
					display: block;
					padding: 5px 0;
					border: 0;
				}


				&[data-label] {

					&::before {
						@include media-breakpoint-down(sm) {
							content: attr(data-label);
							position: relative;
							display: block;

							@include base-font--bold;

							font-size: 10px;
							text-transform: uppercase;

							margin-bottom: 5px;
						}
					}
				}

				p {
					&:first-child {
						margin-top: 0;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			// alt rows - medium
			// ---------------------------------------------
			&:nth-child(odd) {
				@include media-breakpoint-down(sm) {
					background-color: $grey-100;
				}

				td {
					@include media-breakpoint-down(sm) {
						background-color: transparent;
					}
				}
			}
		}
	}

	tfoot {
		@include media-breakpoint-down(sm) {
			display: block;
		}

		tr {
			@include media-breakpoint-down(sm) {
				display: block;
			}

			td {

				@include media-breakpoint-down(sm) {
					display: inline-block;
					text-align: left;

					padding-left: 0;
					padding-right: 0;
				}
			}
		}
	}
}
