.news-list {
	dl {
		position: relative;
		padding-left: 60px;
		padding-top: 30px;
		padding-bottom: 60px;
		border-left: 3px solid $purple;
		box-shadow: 2px 2px 0px 0 rgba(0, 0, 0, 0.1);
		display: flex;
		flex-direction: column;
		margin-bottom: 25px;

		div {
			width:100%;
			display:flex;
			div {
				display: flex;
				flex-direction: column;
				width: 50%;
				padding: 0 5%;
			}
		}
	}
	&__date {
		text-transform: uppercase;
		color: #8a8a8a;
		font-weight: 700;
	}
	&__title {
		margin-top: 15px;
		a {
			@extend %text-style-1;
		}
	}
	&__summary {
		p {
			font-size: 1rem;
			line-height: 1.7rem;
		}
	}
	&__read-more {
		a {
			color: $purple;
			@extend %embedded-sans--bold;
			font-size: .875rem;
		}
	}
}
