/* modules / _sidebar-nav */

.sidebar-nav {
	margin-bottom: 2em;

	.sectionTitle {
		margin: 0;
		font-size: 0.8125rem;
		line-height: 1.3em;

		a {
			display: block;
			padding: 14px 20px;
			color: $sidebar-nav__section-title;

			&:hover {
				background-color: $sidebar-nav__section-title;
			}

			&.on {
				background-color: $sidebar-nav__section-title--current;
				color: #fff;
			}
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	> ul {
		@include clearfix;

		> li {
			margin: 0;
			font-size: 0.8125rem;
			line-height: 1.3em;

			&:hover {
				> a {
					background-color: $sidebar-nav__link-bg--hover;
				}
			}

			&.current {
				> a {
					background-color: $sidebar-nav__link-bg--current;
					color: $white;
				}

				ul {
					margin-top: 12px;
					margin-left: 20px;
					margin-bottom: 12px;

					padding: 0;
					border-left: 1px solid $sidebar-nav__link__nested-border;

					li {
						line-height: 1.3em;
						margin: 0;
						font-size: 0.6825rem;
						@extend %embedded-serif--regular;

						&:hover {
							a {
								background-color: $sidebar-nav__link-bg--hover;
							}
						}

						&.current {
							@extend %base-font--bold;

							a {
								color: #000;
							}
						}

						a {
							padding: 7px 20px;
							color: $sidebar-nav__link__nested-link;
						}
					}
				}
			}

			a {
				display: block;
				padding: 14px 20px;
				color: $sidebar-nav__link;
			}
		}
	}
}

.mura-nav-persistent {
	@extend .sidebar-nav;
}
