/* framework-extended / _card */

.card {
	// becomes:
	// .card-default
	&-default {
		// devnote:
		// add a grey variation
		@include card-variant($card-default-bg, $card-default-border);
	}

	// devnote:
	// fixes some of the spacing on search results modules
	p:last-child {
		margin-bottom: 0;
	}

	&-title {
		font-size: 1.5rem;
	}

	// becomes:
	// .card--no-radius
	&--no-radius {
		// devnote:
		// sets the radius to 0, inherit the value on nested elements
		border-radius: 0;

		.card-img,
		.card-img-top,
		.card-img-bottom,
		.card-header:first-child,
		.card-footer:last-child {
			border-radius: inherit;
		}
	}

	&--no-border {
		border: none;

		.card-header,
		.card-footer {
			border: none;
		}
	}
}
