/* modules / _forms */

form {
	margin: 0;
	&.mura-login-form,
	&.mura-send-login {
		padding: 20px;
		border: 1px solid $grey-300;
	}
	.form-field {
		padding: 5px;
		background-color: $white;
		border: 1px solid #ccc;
		margin-bottom: 2rem;
		height: 50px;
	}
	.form-label {
		@extend %embedded-sans--regular;
		font-size: .6875rem;
		color: $light-grey;
		text-transform: uppercase;
		margin-bottom: 0;
	}
	.form-input {
		border: 0;
		padding: 0;
		@extend %embedded-sans--regular;
		font-size: 1.125rem;
	}
	.mura-form-textfield {
		@extend .form-field;
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 50%;
		}
	}
	.mura-form-textarea {
		@extend .form-field;
		height: 230px;
	}
	label {
		@extend .form-label;
	}
	input[type="text"], textarea {
		@extend .form-input;
	}
	input[type="submit"] {
		height: 50px;
		width: 150px;
		border-radius: 0;
		border: none;
		font-size: .9375rem;
		text-transform: uppercase;
		@extend %embedded-sans--regular;
	}
}

[data-object="form"] {
	> h3 {
		@extend h2;
		margin-top: 5rem;
	}
}



$_text-inputs-list: (
	"[type='color']",
	"[type='date']",
	"[type='datetime']",
	"[type='datetime-local']",
	"[type='email']",
	"[type='month']",
	"[type='number']",
	"[type='password']",
	"[type='search']",
	"[type='tel']",
	"[type='text']",
	"[type='time']",
	"[type='url']",
	"[type='week']",
	"input:not([type])",
	"textarea",
);

fieldset {
	background-color: transparent;
	border: 0;
	margin: 0;
	padding: 0;
}

legend {
	margin-bottom: 15px;
	padding: 0;
	@extend %embedded-serif--regular;
}

label {
	display: block;
}

input,
select,
textarea {
	display: block;
	@extend %embedded-serif--regular;
	font-size: 16px;
}

#{$_text-inputs-list},
select[multiple] {
	background-color: $white;
	border: none;
	border-radius: 0;
	box-shadow: none;
	box-sizing: border-box;
	margin-bottom: 30px;
	padding: 10px;
	width: 100%;

	&:hover {
		border-color: $grey-500;
	}

	&:focus {
		border-color: $link-color;
		box-shadow: none;
		outline: none;
	}

	&:disabled {
		background-color: $grey-100;
		cursor: not-allowed;

		&:hover {
			border: 1px solid $grey-300;
		}
	}
}

textarea {
	resize: none;
}

[type="search"] {
	appearance: none;
}

[type="checkbox"],
[type="radio"] {
	display: inline;
}

[type="file"] {
	margin-bottom: 30px;
	width: 100%;
}

select {
	margin-bottom: 30px;
	max-width: 100%;
	width: auto;
}

// Required Fields
// ---------------------------------------------

form {
	.req {
		label {
			overflow: hidden;

			&::after {
				content:'\002A';
				color: $red-800;
				margin-left: 3px;
			}

			ins {
				display: none;
			}
		}
	}

	p.required {
		&::before {
			content:'\002A';
			color: $red-800;
			margin-right: 3px;
		}
	}
}


// FORM BUILDER
// ---------------------------------------------


.mura-radio-group,
.mura-checkbox-group {
	> div {
		margin-bottom: 15px;
		@extend %base-font--bold;
	}

	label {
		@extend %embedded-serif--regular;
	}

	@at-root .horizontal {
		& {
			label {
				display: inline-block;
				margin-right: 30px;
			}
		}
	}
}
