/* modules / _search */

.search-toggle {
	display: none;

	@include media-breakpoint-up(lg) {
		display: block;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		font-size: 16px;

		height: 30px;
		width: 30px;
		line-height: 30px;
		text-align: center;
	}

	&:hover {
		cursor: pointer;
	}

	i {
		color: $black;
		display: block;
		line-height: inherit;
	}
}


.site-search {
	display: none;
	background-color: #fff;
	border-bottom: 1px solid $divider-color;

	// becomes:
	// .site-search.js__search--open
	&.js__search--open {
		@include media-breakpoint-up(lg) {
			display: block;
		}
	}

	form {
		margin: 0;

		[type="search"] {
			margin-bottom: 0;
			border: none;
			padding: 20px;
		}
	}
}

.site-panel__search {
	form {
		margin: 0;

		[type="search"] {
			padding: 10px 20px;
			margin-bottom: 0;
			border: none;
		}
	}
}
