/* framework-extended / _alert */

.alert {

	// becomes:
	// .alert-default
	&-default {
		// devnote:
		// add a grey variation
		@include alert-variant($alert-default-bg, $alert-default-border, $alert-default-text);
	}

	p:last-child {
		margin-bottom: 0;
	}
}
