/* modules / _index */

.mura-index {
	dl {
		border-bottom: 1px solid $divider-color;
		padding-bottom: 1em;
		margin-bottom: 1em;

		&:last-child {
			border-bottom: 0;
			margin-bottom: 0;
			padding-bottom: 0;
		}

		dt {
			@extend h3;

			a {

			}
		}

		dd {
			margin: .5em 0;

			&.summary {
				p {

					// devnote:
					// zero out the margin of the first and last child
					// this lets the dd margin be the margin source

					&:first-child {
						margin-top: 0;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			&.tags {
				font-size: smaller;
				color: $secondary-text-color;
			}
		}
	}
}

// devnote:
// these are stubs for M7
.mura-collection {}
.mura-collection-item {}
.mura-collection-item__holder {
	[class^='mura-grid'] & {}
}
.mura-item-content {
	img {}
}
.mura-item-meta {}
.mura-item-meta__title {}
.mura-item-meta__date {
	time {}
}
.mura-object-meta {
	h2 {}
}
