/* modules / _panel */

.panel-toggle {
	position: absolute;
	right: 24px;
	top: 0;
	font-size: 20px;
	height: 30px;
	width: 30px;
	line-height: 30px;
	text-align: center;

	@include media-breakpoint-up(lg) {
		display: none;
	}

	&:hover {
		cursor: pointer;
	}

	i {
		color: rgba(0,0,0,.3);
		display: block;
		line-height: inherit;
		font-size: 18px;
	}
}

.site-panel {
	display: none;
	overflow: hidden;

	// becomes:
	// .js__panel--open .site-panel
	.js__panel--open & {
		@include media-breakpoint-down(md) {
			display: block;
		}
	}
	ul {
		li {
			margin: 20px;
			text-align: right;
			&.current {
				a {
					padding-right: 10px;
					margin-right: 10px;
					color: $purple;
					border-right: 2px solid $purple;
				}
			}
			a {
				display: inline-block;
				text-align: right;
				color: $dark-grey;
				padding: 5px;
				position: relative;
				text-align: right;
				@extend %embedded-sans--regular;
				opacity: .7;
				font-size: .75rem;
				text-transform: uppercase;

				&:hover {
					background-color: rgba(255,255,255,.15);
				}

				// &::before {
				// 	@include fa;
				// 	content: '\f105';
				// 	position: absolute;
				// 	right: 20px;
				// 	top: 50%;
				// 	transform: translateY(-50%);
				// 	font-size: 20px;
				// }
			}
		}
	}
}
