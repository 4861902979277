@mixin links($link, $visited:null, $hover:null, $active:null) {
	& {
		color: $link;

		&:visited {
			color: $visited;
		}

		&:hover {
			color: $hover;
		}

		&:active,
		&:focus {
			color: $active;
		}
	}
}

@mixin image-replace () {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

@mixin transition ($transition) {
	-webkit-transition: $transition;
	-moz-transition:    $transition;
	-ms-transition:     $transition;
	-o-transition:      $transition;
}

@mixin font-smoothing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin vertical-center($position:relative) {
	position: $position;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	// firefox bug fix
	outline: 1px solid transparent;
}

@mixin vertical-center--support {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
}
