/* modules / _primary-nav */

.primary-nav {
	display: none;
	clear: both;
	padding-top: 35px;
	@include media-breakpoint-up(lg) {
		display: block;
		padding-right: 35px;
	}

	// Top Level
	//-----------------------
	> ul {
		@include clearfix;

		> li {
			@extend %embedded-sans--regular;
			margin: 0;
			font-size: .85rem;
			margin-bottom: 10px;
			text-align: left;

			&:hover {
				a {
					opacity: 1;
				}

				ul {
					display: block;
				}
			}

			&.current {
				a {
					padding-right: 10px;
					margin-right: 10px;
					opacity: 1;
					border-left: 2px solid $purple;
					color: $purple;
				}
			}

			> a {
				display: inline-block;
				color: $primary-nav-link;
				line-height: 1em;
				padding: 5px 10px;
				opacity: .7;
				text-align: right;
			}
		}
	}
}
