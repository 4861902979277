.site-section {
	padding: 25px 0;
	position: relative;
	@include media-breakpoint-up(lg) {
		padding: 70px 0;
	}
	@include clearfix;
	&:first-of-type {
		margin-top: 16px;
	}
	&:last-of-type {
		margin-bottom: 0;
	}
	&--alternate {
		.site-section__content {
			background: $section-alternate-color;
		}
	}

	&__constrain {
		margin: 0 auto;
		box-sizing: border-box;
		padding: 0 30px;
		@include media-breakpoint-up(md) {
			padding: 0;
		}
		@include clearfix;
		@include make-container-max-widths();
	}

	&__content {
		z-index: $z-index__section-content;
		position: relative;

		@include media-breakpoint-up(lg) {
			margin-left: 252px;
		}

		@include media-breakpoint-up(xl) {
			margin-left: 252px;
		}
	}
}

#ch-about h2 {
	margin:35px 13%;
}

#ch-contact {
	h2 {
		text-align:center;
		display:flex;
		flex-direction: column;
		&::after {
			content: '';
			display: block;
			width:70px;
			height:8px;
			margin:40px auto;
			background-color: $purple;
		}
	}
	p {
		margin:0;
		text-align:center;
	}
	.field-container-0A6A9729-FC85-5B12-8E509E37B0AADF09 {
		display: flex;
		flex-wrap: wrap;
		.mura-control-group {
			width: 50%;
			padding: 10px;

			input {
				margin: 0;
			}
			::placeholder {
				color: #495555;
			}
		}
		.mura-control-group:nth-child(5) {
			width:100%;
			textarea {
				height:200px;
				margin:0;
			}
			::placeholder {
				color: #495555;
			}
		}
	}
	.site-section__content {
		padding: 5% 8%;
	}
}


#frm0A6A9729-FC85-5B12-8E509E37B0AADF09 {
	margin-top: 30px;
	label {
		display: none;
	}
	input[type="text"], textarea {
		padding: 15px; 
		border: 1px solid #e4e4e4;
	} 
	
}

.paging-container-0A6A9729-FC85-5B12-8E509E37B0AADF09 {
	padding: 10px;
}

