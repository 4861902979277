.numeric-list {
	padding: 20px 13%;
	display: flex;
	flex-wrap: wrap;
	&__item {
		width: 45%;
		margin-right:20px;
		padding: 10px 15px;
		background-color: $almost-white;
		border-left: 3px solid $purple;

		&__name {
			margin-bottom: 7px;
			@extend %text-style-1;
		}

		&__description {
			p {
				font-size: .75rem;
			}
		}

	}
}
