.documents-list {
	counter-reset: numbered;
	padding-top: 35px;
	&__item {
		position: relative;
		padding-left: 25px;
		padding-bottom: 30px;
		&::before {
			counter-increment: numbered;
			content: url(../images/file-icon.svg);
			height: 25px;
			width: 18px;
			position: absolute;
			left: 0;
			top: 7px;
		}
		&__name {
			margin-left: 15px;
			a {
				@extend %text-style-1;
			}
		}
		&__size {
			font-size: .75rem;
			color: $light-grey;
			@extend %embedded-sans--regular;
			margin-left: 15px;
		}
	}
}

table.dataTable.display {
	@extend %embedded-sans--regular;
	font-size: .875rem;
	color: $primary-text-color;
	thead {
		tr {
			th {
				border-bottom: 2px solid #000;
				border-top: none;
				text-transform: uppercase;
				font-weight: normal;
			}
			th,
			.sorting,
			.sorting_asc {
				padding: 10px 0;
			}
		}
	}
	tbody {
		tr {
			td,
			td.sorting_1 {
				background-color: #fff;
				padding: 12px 0;
			}
			&.even,
			&.odd {
				td,
				td.sorting_1 {
					background-color: #fff;
				}
			}
			.datatable-date {
				color: $secondary-text-color;
			}
		}
	}
	&.no-footer {
		border-bottom: 1px solid $divider-color;
	}
}

#datatables-filter {
	margin-bottom: 25px;
	overflow: hidden;
	padding-top: 1px;
	.datatables-category {
		width: 100%;
		margin-bottom: 15px;
		.select-menu {
			select {
				@extend %embedded-sans--regular;
				font-size: .875rem;
				color: lighten($dark-grey, 20%);
				height: 40px;
			}
		}
		@include media-breakpoint-up(md) {
			width: 48%;
			margin-right: 4%;
			float: left;
			margin-bottom: 0;
		}
	}
	#documents-list-table_filter {
		position: relative;
		width: 100%;
		label {
			margin-bottom: 0;
			input {
				border: 1px solid $divider-color;
				border-radius: 2px;
				@extend %embedded-sans--regular;
				font-size: .875rem;
				color: lighten($dark-grey, 20%);
				margin-left: 0;
				margin-bottom: 0;
				height: 40px;
			}
			.search-icon {
				width: 20px;
				height: 20px;
				position: absolute;
				background-image: url(../images/search.png);
				background-size: 15px;
				background-repeat: no-repeat;
				top: 12px;
				right: 7px;
				text-indent: 30px;
				white-space: nowrap;
			}
		}
		@include media-breakpoint-up(md) {
			width: 48%;
		}
	}
}

.dataTables_wrapper {
	.dataTables_paginate {
		span {
			.paginate_button,
			.paginate_button.current {
				border: none;
				background: transparent;
				font-size: 14px;
				color: $dark-grey !important;
				&:hover {
					border: none;
					background: transparent;
					font-size: 14px;
					color: $purple !important;
				}
			}
			.paginate_button {
				&:only-child {
					display: none;
				}
			}
		}
	}
}

.select-menu {
	position: relative;
	display: inline-block;
	width: 100%;
	margin-right: 20px;
	border: 1px solid $divider-color;
	border-radius: 2px;
	height: 40px;
	select {
		padding: .5rem 40px .5rem 1rem;
		width: 100%;
		border: none;
		margin-bottom: 0;
		box-shadow: none;
		background: transparent;
		background-image: none;
		-webkit-appearance: none;
		font-size: .8125rem;
		color: $primary-text-color;
		&::-ms-expand {
			display: none;
		}
	}
	&::before {
		font-family: 'FontAwesome';
		content: '\f107';
		position: absolute;
		color: $primary-text-color;
		right: 11px;
		top: calc(50% - 10px);
		pointer-events: none;
		font-size: .8125rem;
		.modal--dark & {
			color: #fff;
		}
	}
}
