/* framework-extended / _type */

.list-inline {
	margin-top: 0;

	.list-inline-item {
		margin-top: 0;
		margin-bottom: 0;
	}
}



.dl-horizontal {
	// devnote:
	// if prior DT are too long, the float clearing can get blown up.
	dt {
		clear: left;
		margin-bottom: .5rem; //this matches bottom margin applied to dd from 01-framework/_reboot.scss
	}
}
