/* framework-extended / _buttons */

// Generate some generic button styles
//-----------------------

$_buttons-list: (
  "button",
  "[type='button']",
  "[type='reset']",
  "[type='submit']"
);

#{$_buttons-list} {
	@extend .btn;
	@extend .btn-primary;
}
