/* modules / _header */

header {
	color: $dark-grey;
	padding: 0;
	border-bottom: 2px solid $divider-color;
	margin-top: 6em;
	z-index: $z-index__header;
	margin-top: 0;
	padding-top: 1rem;
	padding-bottom: 1rem;
	@include media-breakpoint-up(lg) {
		top: 89px;
		position: absolute;
		width: 210px;
		border: 0;
		left: calc((100% - 940px)/2);
		padding-top: 0;
		padding-bottom: 0;
	}

	@include media-breakpoint-up(xl) {
		left: calc((100% - 1140px)/2);
	}

	&.js__sticky--is-sticky {
		@include media-breakpoint-up(lg) {
			position: fixed;
			top: 20px;
		}
		&.js__sticky--too-tall {
			@include media-breakpoint-up(lg) {
				top: 89px;
				position: absolute;
			}
		}
	}

	h1 {
		width: 91px;
		height: 61px;
		margin: 0 auto;
		@include media-breakpoint-up(lg) {
			margin-right: 45px;
			float: right;
			width: 182px;
			height: 102px;
			transform: none;
		}
		a {
			text-indent: 100%;
			display: block;
			height: inherit;
			width: inherit;
			background-image: url('../images/logo.png');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 91px 61px;
			@include media-breakpoint-up(lg) {
				background-size: 182px 102px;
			}
			@include image-replace();
			float: none;
			@include media-breakpoint-up(md) {
				float: right;
			}

		}
		+ p {
			float: right;
			font-size: .75em;
			text-align: right;
			width: 160px;
			padding-right: 45px;
			margin-top: 2px;
			line-height: 1.5;
			@extend %embedded-sans--regular;
			display: none;
			@include media-breakpoint-up(lg) {
				display: block;
			}

		}
	}
}
