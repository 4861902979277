/* modules / _breadcrumbs */

.breadcrumb {
	margin: 0;

	li {
		font-size: 12px;

		+ li {
			// devnote:
			// leveraging a ::before to have more control over the breadcrumb divider
			&::before {
				content: "/";
				display: inline-block;
				padding: 0 3px;
				color: $secondary-text-color;
			}
		}

		a {
			color: $secondary-text-color;
			padding: 5px;

			&:hover {
				color: $primary-text-color;
			}
		}
	}
}
