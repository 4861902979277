/* modules / _pagination */

.mura-next-n {
	border-top: 1px solid $divider-color;
	overflow: hidden;
	margin: 1em 0;
}

.navSequential {
	@extend .pagination;
	@extend .pagination-sm;

	li {
		@extend .page-item;

		a {
			@extend .page-link;
			color: $link-color;
		}

		&:hover {
			a {}
		}

		&.current {
			@extend .page-item.active;

			a {
				background-color: $link-color;
				border-color: $link-color;
			}
		}

		&.navNext,
		&.navPrev {

		}
	}
}
