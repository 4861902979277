// MIXINS FOR FONT ARGS
// ---------------------------------------------
@mixin fa {
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

// SET BASE FONT
// ---------------------------------------------
$base-font--regular: 			-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial;
$base-font--semibold: 			-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial;
$base-font--bold: 				-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial;
$base-websafe:					sans-serif;

// MIXINS FOR FONT ARGS
// ---------------------------------------------

@mixin base-font--regular {
	font-family: #{$base-font--regular}, $base-websafe;
	font-weight: 400;
}

@mixin base-font--semibold {
	font-family: #{$base-font--semibold}, $base-websafe;
	font-weight: 500;
}

@mixin base-font--bold {
	font-family: #{$base-font--bold}, $base-websafe;
	font-weight: 700;
}


// SET EMBEDDED FONT
// ---------------------------------------------

$embedded-sans--regular: 			"trade-gothic-next";
$embedded-sans--bold: 				"trade-gothic-next";

@mixin embedded-sans--regular {
	font-family: #{$embedded-sans--regular}, $base-websafe;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}

@mixin embedded-sans--bold {
	font-family: #{$embedded-sans--bold}, $base-websafe;
	font-weight: 700;
	-webkit-font-smoothing: antialiased;
}

$embedded-serif--regular: 			"tgn-soft-round";
$embedded-serif--italic: 			"tgn-soft-round";
$embedded-serif--bold: 				"tgn-soft-round";

@mixin embedded-serif--regular {
	font-family: #{$embedded-serif--regular}, $base-websafe;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
}

@mixin embedded-serif--italic {
	font-family: #{$embedded-serif--italic}, $base-websafe;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
}

@mixin embedded-serif--bold {
	font-family: #{$embedded-serif--bold}, $base-websafe;
	font-weight: 700;
	-webkit-font-smoothing: antialiased;
}
